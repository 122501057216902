.container {
    max-width: 700px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    margin: auto;
    border-radius: 12px;
    background-image: linear-gradient(180deg, #130754 0%, #3b2f80 100%);
    color: white;


    @media (max-width: 767px) {
        width: 100%;
    }
}

.top-bar {
    display: flex;
    justify-content: center;
    gap: 14px;
    align-items: center;
    margin-top: 8px;


    @media (max-width: 550px) {
        flex-wrap: wrap;
        max-height: 20vh;

    }
}

.top-bar input {
    display: flex;
    max-width: 90vw;
    height: 78px;
    background-color: #ebfffc;
    border: none;
    outline: none;
    border-radius: 40px;
    padding-left: 40px;
    color: #626262;
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 550px) {
        max-height: 58px;
    }
}

.btn-submit {
    width: 58px;
    height: 58px;
    background-color: #ebfffc;
    border-radius: 40px;
    cursor: pointer;

    @media (max-width: 550px) {
        height: 58px;
        width: 58px;
    }
}


.weather-image {
    margin: 12px auto 0;
    display: flex;
    height: 20vh;

    @media (max-height: 630px) {
        width: 80px;
        height: 80px;
    }
}

.weather-temp {
    display: flex;
    justify-content: center;
    font-size: 90px;
    font-weight: 400;

    @media (max-width:550px) {
        font-size: 65px;
    }
}

.weather-location {
    display: flex;
    justify-content: center;
    font-size: 60px;
    font-weight: 400;

    @media (max-width:550px) {
        font-size: 50px;
    }

}

.data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;

    @media (max-width: 440px) {
        flex-direction: column;
        gap: 16px;
    }
}

.elements {
    display: flex;
    gap: 12px;
    align-items: center;

    @media (min-width: 700px) {
        margin-top: 50px;
    }

    @media (max-width: 550px) {
        flex-direction: column;
    }


}

.element {
    margin: auto;
    display: flex;
    align-items: center;
    gap: 12px;
}

.data {
    font-size: 34px;
    font-weight: 400;
}

.text {
    font-size: 24px;
    font-weight: 400;
}

.icon {
    margin-top: 10px;
    height: 48px
}

h3 {
    font-size: 36px;
}